<template>
  <v-container fluid>
    <v-row>
      <v-col class="col-12">
        <h1>Add BOQ</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-4">
        <v-text-field
          label="Description"
          v-model="boq.description"></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-4">
        <v-text-field
          label="Units"
          v-model="boq.units"></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-4">
        <v-text-field
          label="Standard Cost (£)"
          type="number"
          step="0.01"
          v-model="boq.standard_cost"></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-4">
        <v-text-field
          label="Bonus Rate (£)"
          type="number"
          step="0.01"
          v-model="boq.bonusRate"></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-4">
        <v-btn @click="saveBoq">Save</v-btn>
      </v-col>
    </v-row>
    <v-dialog
      v-model="showMessage"
      max-width="290"
    >
      <v-card>
        <v-card-text class="pt-4">
          {{ message }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="orange darken-2"
            text
            @click="$router.push('/admin/boqs/index')"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>  
</template>

<script>
import axios from '../../../axios';

export default {
  name: 'AdminBoqEdit',
  computed: {
    token() {
      return this.$store.state.token;
    },
    userid() {
      return this.$store.state.userid;
    },
  },
  data() {
    return {
      boq: {
        description: '',
        units: '',
        standard_cost: 0,
        bonusRate: 0,
      },
      message: '',
      showMessage: false,
    };
  },
  methods: {
    saveBoq() {
      axios.post(`/boqs/create.json?token=${this.token}&userid=${this.userid}`, this.boq)
        .then((response) => {
          this.boq = response.data.boq;
          this.boq.standard_cost = response.data.boq.standard_cost / 100;
          this.boq.bonusRate = response.data.boq.bonusRate / 100;
          this.message = response.data.message;
          this.showMessage = true;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
